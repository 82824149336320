.no-camp {
    max-width: 996px;
    width: 90%;
    height: 132px;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    align-items: center;
    border: 1px solid var(--Main-Text, #242424);
    background: #F9F9F9;
}

.no-head {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-left: 24px;
}

.no-head h3 {
    color: var(--Main-Text, #242424);
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.no-head p {
    color: #000;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 325px;
    text-align: left;
}

.start {
    border-radius: 8px;
    background: #5AB581;
    display: flex;
    width: 158px;
    height: 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    color: #FFF;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: 24px;
    cursor: pointer;
}

.campaigns {
    border-radius: 8px;
    border: 1px solid var(--Main-Text, #242424);
    background: #F9F9F9;
    max-width: 996px;
    width: 100%;
    height: 572px;
    flex-shrink: 0;

}

.campaigns h3 {
    color: var(--Main-Text, #242424);
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 30px;
    display: flex;
    margin-top: 28px;
    margin-bottom: 16px;
}

.campaigns p {
    color: #000;
    text-align: left;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 325px;
    margin-left: 30px;
    margin-bottom: 23px;
}

.camp-title {
    border-bottom: 1px solid #959595;
    background: #EEE;
    width: 100%;
    height: 47px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
}

.ctitles1 {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.tits {
    width: 144px;
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.camps {
    display: flex;
    border-bottom: 1px solid var(--Main-Text, #242424);
    background: #F9F9F9;
    max-width: 994px;
    width: 100%;
    height: 111px;
    justify-content: space-around;
    align-items: center;
    flex-shrink: 0;
}

.camp-name {
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    /* 62.5% */
    max-width: 200px;
    width: 100%;
    height: 35px;
    flex-shrink: 0;
    text-align: left;

}
.back2,
.back3{
    border-radius: 8px;
    background: #5AB581;
    display: flex;
    width: 138px;
    height: 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    color: #FFF;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    
}
.back3{
    display: none;
}
.back2{
    margin-right: 48px;
}
.camp-type {
    color: #242424;
    text-align: center;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    max-width: 158px;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 8px;
    height: 32px;
}

.camp-status {
    max-width: 134px;
    width: 100%;
    color: #B88D00;
    text-align: center;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.camp-cover {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 390px;
}

.camp-cover::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.camp-cover {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.detailed-view {
    border-radius: 8px;
    border: 1px solid var(--Main-Text, #242424);
    background: #F9F9F9;
    max-width: 996px;
    width: 90%;
    flex-shrink: 0;
    margin-top: 63px;
    padding-bottom: 28px;
}

.detailed-view h3 {
    max-width: 371px;
    color: var(--Container, #0C2D1A);
    font-family: Archivo Black;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin-left: 68px;
    margin-top: 30px;
    margin-bottom: 37px;
}

.details {
    display: flex;
    width: 100%;
    gap: 14px;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 21px;
}

.detail {
    border-radius: 8px;
    border: 1px solid #000;
    background: #FFF;
    max-width: 429px;
    width: 100%;
    height: 270px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}



.deta {
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 13px;
}

.deta h3 {
    color: var(--Container, #0C2D1A);
    font-family: Archivo;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    max-width: 201px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    text-align: left;
}
.large-font{
    font-size: 18px !important;
}

.cam-type {
    color: #242424;
    text-align: center;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    width: 158px;
    height: 33px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
}

.detail p {
    color: #000;
    font-family: Raleway;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 368px;
    margin-bottom: 33px;
    text-align: left;

}

.ditems {
    display: flex;
    justify-content: center;
    column-gap: 8px;
    flex-wrap: wrap;
    row-gap: 5px;
}

.ditem {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    width: 158px;
    height: 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #242424;
    text-align: center;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.cam-type1 {
    color: #242424;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    min-width: 183px;
    height: 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
}

.ditems1 {
    background: #F9F9F9;
    max-width: 367px;
    width: 100%;
    height: 139px;
    flex-shrink: 0;

}

.inner-frame {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    width: 90%;
}

.inner-frame h3 {
    color: var(--Main-Text, #242424);
    font-family: Raleway;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
}

.leads {
    gap: 9px;
    align-items: baseline;
    height: 83px;
    overflow-y: scroll;
    display: flex;
    justify-content: flex-start;
}
.leads::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .leads {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

.leads h3 {
    color: var(--Main-Text, #242424);
    font-family: Raleway;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    max-width: 326px;
    text-align: left;
}

.leads h4 {
    color: #606368;
    font-family: Raleway;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    justify-content: center;

}

.leads h5 {
    color: #81858C;
    font-family: Raleway;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 0;
}

.creator-detail {
    border-radius: 8px;
    border: 1px solid var(--Main-Text, #242424);
    background: var(--BG, #FEFEFE);
    max-width: 872px;
    width: 100%;
    height: 309px;
    flex-shrink: 0;
}

.creator-detail h3 {
    color: var(--Container, #0C2D1A);
    font-family: Archivo;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    max-width: none;

}

.creator-detail p {
    color: #000;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.headingsss {
    margin-top: 45px;
    margin-bottom: 45px;
    width: 90%;
    align-items: baseline;
}

.headingsss h3 {
    color: var(--Container, #0C2D1A);
    font-family: Archivo;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.headingsss p {
    color: #000;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
}

.campaign-creator {
    gap: 15px;
    overflow-y: scroll;
    width: 90%;
    justify-content: flex-start;

}

.Screator-wrap2 {
    display: flex;
    max-width: 736.275px;
    width: 100%;
    align-items: center;
    background-color: #f9f9f9;
    min-height: 66px;
    justify-content: space-around;
    gap: 30px;
    flex-shrink: 0;
    position: relative;
    cursor: pointer;
}

.shead1 h3 {
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 8px;
    /* 125% */
}

.camp-creator-wrap {
    display: flex;
    width: 90%;

}

.view-post {
    display: flex;
    justify-content: center;
    align-items: center;
}

.shead1 {
    display: flex;
    max-width: 160px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    gap: 4px;
}

.postss {
    border-radius: 8px;
    background: #5AB581;
    display: flex;
    width: 138px;
    height: 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    color: #FFF;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.sname1 {
    max-width: 208px;
    width: 100%;

}

.shead1 h4 {
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    /* 153.846% */
}

.shead1 h5 {
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
}

.camp-creator-wrap2 {
    display: none;
}

.goal {
    color: #242424;
    text-align: center;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.gold {
    color: var(--Yellow, #B88D00);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.Screator-wrap1{
    display: flex;
    width: 100%;
    justify-content: space-around;
    gap: 4px;

}
.disabled{
    background-color: grey !important;
}
.manage-back{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.back1{
    color: #407657;
    font-family: Raleway;
    font-size: 12.627px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 6.313px;
    border: 0.789px solid #407657;
    display: flex;
    width: 133px;
    height: 48px;
    justify-content: center;
    align-items: center;
    gap: 6.313px;
    flex-shrink: 0;
    cursor: pointer;
    margin-right: 59px;
}

.campaign-creator::-webkit-scrollbar {
  width: 12px;
}

/* Track */
.campaign-creator::-webkit-scrollbar-track {
  background: #959595;
}

/* Handle */
.campaign-creator::-webkit-scrollbar-thumb {
  background: #0C2D1A;
}

/* Handle on hover */
.campaign-creator::-webkit-scrollbar-thumb:hover {
  background: #555;
}
@media (max-width:1050px) {
    .campaigns {
        width: 95%;
    }

    .camps {
        flex-wrap: wrap;
    }

    .headingss h3 {
        color: var(--Container, #0C2D1A);
        font-family: Archivo;
        font-size: 18.94px !important;
        font-style: normal;
        font-weight: 600 !important;
        line-height: normal;
        text-transform: uppercase;
    }

    .headingss p {
        color: #000;
        font-family: Raleway;
        font-size: 12.627px !important;
        font-style: normal;
        font-weight: 400 !important;
        line-height: normal;
    }
}

@media (max-width:850px) {
    .camp-name {
        color: var(--Main-Text, #242424);
        font-family: Inter;
        font-size: 12.627px;
        font-style: normal;
        font-weight: 700;
        width: 157.831px;
        height: 27.62px;
        flex-shrink: 0;
    }

    .camp-type {
        color: #242424;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: Raleway;
        font-size: 12.627px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border-radius: 6.313px;
        background: #FFF;
        box-shadow: 0px 0.78916px 3.15663px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        width: 124.687px;
        justify-content: center;
        align-items: center;
        gap: 6.313px;
    }

    .camp-status {
        color: #B88D00;
        text-align: center;
        font-family: Raleway;
        font-size: 12.627px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .nexts {
        color: #FFF;
        leading-trim: both;
        text-edge: cap;
        font-family: Raleway;
        font-size: 12.627px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        display: flex;
        width: 74.97px;
        height: 25.253px;
        justify-content: center;
        align-items: center;
        gap: 6.313px;
        flex-shrink: 0;
    }
}

@media (max-width:655px) {
    .camp-creator-wrap {
        display: none;
    }

    .camp-creator-wrap2 {
        display: flex;
        display: flex;
        width: 655px;
        width: 100%;
        padding: 7px 0px 12px 0px;
        flex-direction: column;
        align-items: center;
        gap: 11px;
        background-color: #f9f9f9;

    }

    .postss1 {
        border-radius: 6.313px;
        background: #5AB581;
        display: flex;
        height: 25px;
        padding: 3.416px 39.458px;
        justify-content: center;
        align-items: center;
        gap: 6.313px;
        width: 90%;
    
    }
    .back2{
        display: none;
    }
    .back3{
        display: flex;
        margin-top: 24px;

    }
    .manage-back{
        justify-content: center;
    }
    .campaign-creator::-webkit-scrollbar {
  display: none;
}

.campaign-creator {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

}

@media (max-width:655px) {
    .shead1 h3 {
        color: var(--Main-Text, #242424);
        font-family: Inter;
        font-size: 12.627px;
        font-style: normal;
        font-weight: 500;
        line-height: 15.783px;
        /* 125% */
    }

    .shead1 h4 {
        color: var(--Main-Text, #242424);
        font-family: Inter;
        font-size: 10.259px;
        font-style: normal;
        font-weight: 400;
        line-height: 15.783px;
        /* 153.846% */
    }

    .sname1 {
        /* max-width: 136px; */
justify-content: flex-start;
    }

    .Screator-wrap2 {
        gap: 0;
        justify-content: center;
    }

    .detailed-view {
        border: none;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .deta h3 {
        color: var(--Container, #0C2D1A);
        font-family: Archivo;
        font-size: 18.94px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
    }

    .detail p {
        color: #000;
        font-family: Raleway;
        font-size: 10.259px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 95%;
    }

    .cam-type {
        display: flex;
        width: 124.687px;
        height: 26.042px;
        color: #242424;
        text-align: center;
        font-family: Raleway;
        font-size: 12.627px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .cam-type1 {
        width: 144.416px;
        height: 18.94px;
        color: #242424;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .detailed-view h3 {
        margin-top: 0;
        margin-left: 0;
        margin-bottom: 0;

    }
    .no-camp{
        flex-direction: column;
        padding-top: 12px;
        padding-bottom: 12px;
        align-items: baseline;
        }
}

@media (max-width:400px) {
    .headingsss h3 {
        color: var(--Container, #0C2D1A);
        font-family: Archivo;
        font-size: 18.94px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
    }

    .headingsss p {
        color: #000;
        font-family: Raleway;
        font-size: 10.259px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;
    }
    .shead2{
        width: auto;
    }
    .deta{
        flex-direction: column;
        gap: 9px;
    }
    .detailed-view h3 {
        display: flex;
        justify-content: center;
        text-align: left;
    }
    .detail{
        height: auto;
        padding-top: 16px;
        padding-bottom: 16px;
    }
}