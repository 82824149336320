.leftnav {
  background: #2F2F2F;
  width: 100%;
  height: 86px;
  display: none;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 10;
}

.logos {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #B88D00;
  font-family: Playfair Display;
  font-size: 19.167px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin-right: 45px;
  cursor: pointer;
}

.line3 {
  margin-left: 45px;
}

/* Right-side navbar */
.right-navbar {
  position: fixed;
  top: 85px;
  left: -240px;
  width: 240px;
  height: 100vh;
  background-color: #242424;
  transition: left 0.3s ease-in-out;
  z-index: 11;
}

.right-navbar ul {
  list-style: none;
  padding: 0;
}

.right-navbar ul li {
  padding: 10px 20px;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.2s;
}


.searches {
  display: flex;
  align-self: center;
  justify-content: center;
  flex-grow: 0.8;

}

.pr {
  padding-right: 40px;
}

/* Open the right-side navbar */
.right-navbar.open {
  left: 0;
}

.left {
  display: flex;
  justify-content: space-around;
  padding: 19px;
  padding-right: 13px;
  padding-left: 0px;

}

.sign-in-btn {
  display: flex;
  width: 124px;
  height: 32px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #FEFEFE;
  background-color: #1F262D;
  color: #FEFEFE;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}




@media (max-width:1300px) {
  .navbar {
    display: none;
  }

  .leftnav {
    display: flex;
  }

  .right {
    /* margin-top: 86px; */
  }

  .general-right {
    /* margin-top: 60px; */
  }
  .App{
    display: block;
  }
}
@media (max-width:800px) {
  .line3{
    height: 57px;
    position: relative;
    top: 16px;
    width: 57px;
  }
}
@media (max-width:400px) {
  .line3{
    height: 53px;
    position: relative;
    top: 16px;
    width: 50px;
    margin-left: 20px;
  }
}