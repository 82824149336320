.filter-label {
    display: flex;
    align-items: center;
    position: relative;
}

.filter-options {
    position: absolute;
    top: 34px;
    left: -53px;
    display: flex;
    width: 296px;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px;
    background-color: #FFF;
    z-index: 1;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
}

.filter-option {
    border-radius: 10px;
    display: flex;
    height: 32px;
    padding: 9px 18px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    z-index: 5;
    background: var(--Pinterest-White, #FFF);

}

.filter-option:hover {
    border-radius: 10px;
    background: #EFEFEF;
}
.menu-option:hover {
    border-radius: 10px;
    background: #EFEFEF;
    cursor: pointer;
}

.search-bars {
    margin-top: 72px;
    display: flex;
    width: 100%;
    justify-content: flex-end;

}

.search-bar {

    display: flex;
    max-width: 524px;
    width: 90%;
    align-items: center;
    height: 64px;
}

.search {
    border-radius: 16px;
    border: 1px solid #000;
    background: #F9F9F9;
    max-width: 524px;
    width: 100%;
    height: 60px;

}

.searchInputs {
    display: flex;
    border-radius: 16px;
    padding-right: 8px;

}

.search input {
    background-color: white;
    border: 0;
    border-radius: 2px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: 18px;
    padding: 15px;
    max-width: 524px;
    width: 100%;
    border-radius: 16px;

}

.searchIcon {
    height: 60px;
    width: 50px;
    background-color: white;
    display: grid;
    place-items: center;
    border-radius: 16px;

}

input:focus {
    outline: none;
}

.searchIcon svg {
    font-size: 35px;
}

.dataResult {
    margin-top: 12px;
    height: auto;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    overflow-y: auto;
    z-index: 3;
    position: relative;
    border-radius: 16px;
    border: 1px solid #000;
}

.dataResult::-webkit-scrollbar {
    display: none;
}

.dataResult .dataItem {
    width: 91%;
    height: 72px;
    display: flex;
    align-items: center;
    color: black;
}

.dataItem p {
    margin-left: 10px;
}

a {
    text-decoration: none;
}

.dataResult a {
    background: #FFF;
    display: flex;
    max-width: 524px;
    width: 100%;
    height: 72px;
    padding: 0px 24px;
    justify-content: space-between;
    align-items: center;
}

.dataResult a:hover {
    background-color: #F4F8FB;

}

#clearBtn {
    cursor: pointer;
}

.sname {
    color: var(--gray-900, #101828);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
}

.sfollower {
    color: var(--gray-500, #667085);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
}

.cat {
    display: flex;
    flex-direction: column;
}

.sname {
    display: flex;
    gap: 12px;
}

.sname img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.scat {
    color: var(--gray-500, #667085);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    text-align: left;
}

.creator-menu {
    position: absolute;
    right: 150px;
    border-radius: 10px;
    background: var(--Pinterest-White, #FFF);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    max-width: 296px;
    width: 100%;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
}

.menu-option {
    border-radius: 10px;
    display: flex;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    color: #000;
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 150% */
}

.addtoc {
    border-radius: 8px;
    background: #5AB581;
    max-width: 250px;
    width: 100%;
    height: 48px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    gap: 12px;
    margin-right: 100px;
    position: relative;
    flex-shrink: 0;
    cursor: pointer;
}

.addtoc img {
    position: relative;
}

.selectedno {
    position: absolute;
    color: #FFF;
    font-family: Raleway;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    right: 52px;
    top: 20px;

}

/* -----------------------checkbox----------------------------------------------- */

.custom-checkbox input[type="checkbox"] {
    display: none;
}

.custom-checkbox label {
    /* Style the custom checkbox */
    position: relative;
    cursor: pointer;
}

.custom-checkbox label:before {
    /* Checkbox icon */
    content: "";
    position: absolute;
    left: 0;
    top: -10px;
    width: 20px;
    /* Adjust size as needed */
    height: 20px;
    /* Adjust size as needed */
    border: 2px solid rgba(184, 141, 0, 1);
    /* Checkbox border color */
    background-color: #fff;
    /* Checkbox background color */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

.custom-checkbox input[type="checkbox"]:checked+label:before {
    /* Checked state */
    content: '🗸';
    border-color: rgba(184, 141, 0, 1);
    color: rgba(184, 141, 0, 1);
}

.selected {
    background-color: #FFFEF2 !important;
}

.addtoC {}

.addtoC h3 {
    color: var(--Main-Text, #242424);
    font-family: Raleway;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    margin-top: 27px;
    margin-bottom: 30px;
    width: 90%;
}

.emplyCollection {
    border-radius: 8px;
    background: #F1FFF7;
    max-width: 744px;
    width: 90%;
    height: 345px;
    flex-shrink: 0;
    color: #000;
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
}

.createC {
    border-radius: 8px;
    background: #5AB581;
    display: flex;
    padding: 17px 50px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: var(--BG, #FEFEFE);
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 42px;
    cursor: pointer;
}

.collectionh {
    border-radius: 8px;
    background: #8FC9A8;
    max-width: 744px;
    width: 90%;
    height: 345px;
    flex-shrink: 0;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.coll1 {
    width: 90% !important;
}

input[type='radio'] {
    /* Hide default radio button */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    border: none;
    /* margin-right: 8px; */

}

/* Style the custom radio button */
input[type='radio']::before {
    content: '';
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    width: 17px;
    height: 17px;
    border: 2px solid var(--Button-normal, #0C2D1A);
    border-radius: 50%;
    /* Make the border round */
    background-color: transparent;
    /* Background color for the radio button */
}

/* Style the dot inside the custom radio button */
input[type='radio']:checked::before {
    content: '⬤';
    display: flex;
    justify-content: center;
    align-items: center;
}

.btns {
    display: flex;
    gap: 14px;
    margin-top: 42px;
}

.createcoll {
    border-radius: 8px;
    border: 1.5px solid #407657;
    display: flex;
    padding: 12px 50px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #407657;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;

}

.addToCollectionBtn {
    border-radius: 8px;
    background: #5AB581;
    display: flex;
    padding: 12px 50px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #FFF;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
}

.select-homme input[type="checkbox"] {
    display: none;
}

.select-homme label {
    /* Style the custom checkbox */
    position: relative;
    cursor: pointer;
}

.select-homme label:before {
    /* Checkbox icon */
    content: "";
    position: absolute;
    left: 0;
    top: -10px;
    width: 37px;
    height: 37px;
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    opacity: 1;
}

.select-homme input[type="checkbox"]:checked+label:before {
    /* Checked state */
    content: '🗸';
    border-color: rgba(184, 141, 0, 1);
    color: white;
    opacity: 1;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.75) 100%);

}

.Screator1 {
    display: none;
}

.mobile-filters {
    z-index: 2;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 10px 10px 0px 0px;
    background: var(--Pinterest-White, #FFF);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    width: 100%;
    min-height: 315px;
    padding: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.done-black {
    color: #FFF;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    /* 187.5% */
    border-radius: 10px;
    background: #2F2F2F;
    display: flex;
    height: 48px;
    width: 96%;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;

}

.filter-item {
    border-radius: 10px;
    display: flex;
    width: 96%;
    min-height: 48px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
    flex-direction: column;

}

.filter-item h3 {
    color: #000;
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 150% */
}

.filter-item img {
    cursor: pointer;
}

.custom-drop {
    position: relative;
}


.droparr {
    right: 4px;
    top: 6px;
    position: absolute;
}

.rotated {
    transform: rotate(180deg);
}
.mobileul{
    width: 100%;
    padding-left: 0px;

}
.mfilter-option{
    background: #F2F2F2;
    display: flex;
height: 32px;
justify-content: space-between;
align-items: center;
flex-shrink: 0;
align-self: stretch;
color: #000;
font-family: Raleway;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 187.5% */
padding-left: 48px;

}

.mc{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-left: 12px;
    margin-right: 12px;
}
.mc img{
    margin-right: 22px;
}
.mc h3{
    margin-left: 22px;
}

.popup {
    display: flex;
    justify-content: center;
    align-items: baseline;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
  }
  
  .popup-content {
    max-width: 823px;
    min-height: 448px;
    flex-shrink: 0;
    border: 8px solid var(--Container, #0C2D1A);
background: var(--White, #FEFEFE);
    text-align: center;
    position: relative;
    width: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    top: 117px;

  }
  .content2{
    gap: 60px;
    justify-content: center;

  }
  .input2{
    max-width: 497px;
    width: 90% !important;
  }
  .input2 input{
    color: #383C3E;
text-align: center;
font-family: Inter;
font-size: 16px !important;
font-style: normal;
font-weight: 400;
line-height: 160%; /* 25.6px */
letter-spacing: 0.454px;
max-width: 468.642px;
width: 90% !important;
  }
  .popup-content h2{
    color: var(--Container, #0C2D1A);
font-family: Playfair Display;
font-size: 48px;
font-style: normal;
font-weight: 100;
line-height: normal;
text-transform: uppercase;
  }
  
  .close-popup {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 24px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .head1{
    color: var(--White, #FEFEFE);
text-align: right;
text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
font-family: Archivo Black;
font-size: 32px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
  }
  .head1 span{
    color: #DAA804;
font-family: Archivo Black;
font-size: 32px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
  }

  .cross{
    display: flex;
    width: 94%;
    justify-content: flex-end;
    margin-top: 16px;
cursor: pointer;
  }
  .custom-input {
    position: relative;
    display: flex;
padding: 10.899px 0px;
flex-direction: column;
align-items: flex-start;
gap: 10.899px;
width: 100%;
height: 51px;

}

.custom-input label {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    transition: 0.3s ease-out;
}

.custom-input input,
.custom-input textarea {
    width: 100%;
    border: none;
    border-bottom: 1px solid#B88D00; /* Bottom line */
    padding: 5px;
    font-size: 16px;
    transition: 0.3s ease-out;

}

.custom-input input:focus,
.custom-input textarea:focus {
    border-bottom: 2px solid rgba(184, 141, 0, 1); /* Highlighted line color */
}

.custom-input input:focus + label,
.custom-input textarea:focus + label,
.custom-input input:valid + label,
.custom-input textarea:valid + label {
    top: -20px; /* Adjust this value to control label position when focused or not empty */
    font-size: 12px; /* Adjust this value for smaller label size when focused or not empty */
    color: rgba(184, 141, 0, 1); /* Label color when focused or not empty */
    border:none
}

.submit-btn {
    width: 250px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 8px;
background: var(--Secondarty-Text, #0C2D1A);
color: var(--White, #FEFEFE);
text-align: center;
font-family: Raleway;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
display: flex;
justify-content: center;
align-items: center;
gap: 8px;
margin-bottom: 48px;
cursor: pointer;
  }
  .submit-btn:hover{
    border-radius: 8px;
background: var(--Main-Text, #242424);
.send{

  animation: send 0.5s forwards;
}
  }

  .scroller{
    height: 400px;
    overflow-y: scroll;
  }
  .scroller::-webkit-scrollbar {
    display: none;
}
@media (max-width:500px) {
    .Screator1 {
        display: flex;
        justify-content: center;
        border-bottom: 0.695px solid var(--Main-Text, #242424);
    }

    .creator {
        display: none;
    }

    .thead {
        display: none;
    }

    .Screator-wrap3 {
        width: 100%;
        gap: 0px !important;
    }
}
@media (max-width: 928px){


  .submit-btn{
    border-radius: 8px;
    background: var(--Main-Text, #242424);


  }
  .send{
    transform: rotate(-23deg);

  }
  } 
  @media (max-width:460px) {


    .popup-content h2{
        font-size: 38px;
    }
}
  @media (max-width:350px) {
    .popup-content h2{
        font-size: 29px;
    }
}