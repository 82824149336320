.pricavy-head{
    color: var(--Container, #0C2D1A);
leading-trim: both;
text-edge: cap;
font-family: "Archivo Black";
font-size: 48px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
max-width: 689px;
width: 100%;
margin-top: 60px;
margin-bottom: 40px;
display: flex;
}
.policy-box{
    border-radius: 8px;
border: 1px solid #000;
background: #FFF;
max-width: 1026px;
width: 100%;
height: 715px;
flex-shrink: 0;
display: flex;
flex-direction: column;
gap: 64px;
align-items: center;
overflow-y: scroll;

padding-top: 64px;
padding-bottom: 40px;
}
.policy-box::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .policy-box {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
.privacy-wrap{
    overflow-y: scroll;
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 64px;
}
.privacy-wrap::-webkit-scrollbar {
    width: 12px;
  }
  
  /* Track */
  .privacy-wrap::-webkit-scrollbar-track {
    background: #959595;
  }
  
  /* Handle */
  .privacy-wrap::-webkit-scrollbar-thumb {
    background: #0C2D1A;
  }
  
  /* Handle on hover */
  .privacy-wrap::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
.privacies{
max-width: 838px;
width: 100%;
}
.privacies h3{
    color: #000;
    font-family: Archivo Black;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    display: flex;
    text-align: left;

}
.privacies p{
    color: #000;
    font-family: Archivo;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    text-align: left;
    flex-direction: column;

    

}