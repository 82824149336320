.collection {
  display: flex;
  margin-top: 107px;
  display: flex;
  margin-top: 107px;
  align-items: flex-end;
  justify-content: space-around;
  margin-bottom: 24px;
}

.collection h3 {
  color: #0C2D1A;
  font-family: Archivo Black;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
}

.collection p {
  color: #000;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  max-width: 595px;
  display: flex;
  text-align: left;
}

.cbutton {
  border-radius: 8px;
  border: 2px solid #242424;
  display: flex;
  width: 199px;
  height: 48px;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #242424;
  text-align: center;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: Bold;
  line-height: normal;
  cursor: pointer;
}

.ctitle {
  border-bottom: 1px solid #959595;
  background: #EEE;
  width: 100%;
  height: 47px;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ctitles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 65%;
  margin-left: 26px;
}

.citems {
  background-color: #F9F9F9;
  width: 100%;
  min-height: 92px;
}

.citems h3 {
  color: #000;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.not {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 92px;
  gap: 19px;
  flex-direction: column;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

}

.create-collection-popup {
  max-width: 852px;
  width: 100%;
  height: 632px;
  background-color: #FEFEFE;
  padding: 20px;
  position: absolute;
  justify-content: baseline;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  overflow: hidden;
  z-index: 5;
}

.cross {
  position: absolute;
  right: 15px;
  top: 14px;
  cursor: pointer;
}

.h3 {
  display: flex;
  flex-direction: column;
  max-width: 296px;
  ;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 75px;

}

.h3 img {
  width: 141px;
  height: 11px;
}

.h3 h3 {
  color: var(--Main-Text, #242424);
  text-align: center;
  font-family: Raleway;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 53px;
  width: 100%;

}

.write {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  max-width: 460px;
  width: 90%;
  margin-bottom: 32px;
}

.write h3 {
  color: var(--Main-Text, #242424);
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 0;
  margin-bottom: 0;
}

.write input,
.write textarea {
  color: #959595;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
}

.write input {
  border-radius: 8px;
  background: #EEE;
  max-width: 460px;
  width: 100%;
  height: 48px;
  flex-shrink: 0;
  padding-left: 28px;
}

.write textarea {
  resize: none;
  border-radius: 8px;
  background: #EEE;
  max-width: 460px;
  width: 100%;
  height: 120px;
  flex-shrink: 0;
  padding: 19px 19px 0px 28px;
}

.proceed {
  border-radius: 8px;
  background: #5AB581;
  display: inline-flex;
  padding: 17px 50px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #FFF;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

.vibe {
  position: absolute;
  right: 0;
  bottom: 0;
}

.add-creator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.addh3 {
  margin-top: 28px;
  margin-bottom: 58px;
}

.addh3 h3 {
  color: var(--Main-Text, #242424);
  text-align: center;
  font-family: Raleway;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
  width: 100%;
}

.btns1 {
  display: flex;
  gap: 11px;
  justify-content: center;
  align-items: center;
  margin-top: 0;
}

.skip {
  border-radius: 8px;
  border: 1px solid #407657;
  display: flex;
  padding: 0px 50px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #407657;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 41px;
  height: 32px;
  cursor: pointer;
}

.done {
  color: #FFF;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 8px;
  background: #5AB581;
  display: flex;
  height: 32px;
  padding: 0px 50px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 41px;
  height: 32px;
  cursor: pointer;

}

.select-creator {
  border-radius: 8px 8px 0px 0px;
  background: #8FC9A8;
  max-width: 534px;
  width: 100%;
  height: 469px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding-top: 23px;
  flex-direction: column;
  overflow-y: scroll;
}

.Screator {
  max-width: 438px;
  width: 100%;
  height: 96px;
  background-color: rgba(255, 255, 255, 1);
}

.Sname {
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
position: relative;
}

.Sname img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}

.Shead {
  display: flex;
  flex-direction: column;
}

.Shead h3 {
  color: var(--Main-Text, #242424);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 125% */
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
}

.Shead h4 {
  color: var(--Main-Text, #242424);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  /* 153.846% */
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;

}

.Screator-wrap {
  max-width: 437px;
  height: 99px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 30px;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
}

.fc {
  display: flex;
  flex-direction: column;
  width: 94px;
  height: auto;
  justify-content: center;

}

.Ssocial {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.Ssocial img {
  width: 24px;
  height: 24px;
}

.Sfollower {
  color: var(--Yellow-Text, #B88D00);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 125% */
}

.Scontent-type {
  color: var(--Main-Text, #242424);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
}

.select-creator::-webkit-scrollbar {
  display: none;
}

.select-creator {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
/* .campaign-creator::-webkit-scrollbar {
  display: none;
}

.campaign-creator {
  -ms-overflow-style: none;
  scrollbar-width: none;
} */

.Cname {
  color: var(--Main-Text, #242424);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.Ccreators,
.Ccategory,
.Caction {
  color: var(--Main-Text, #242424);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.coll {
  width: 100%;
  min-height: 111px;
  flex-shrink: 0;
  border-bottom: 1px solid var(--Main-Text, #242424);
  background: #F9F9F9;
  display: flex;
  justify-content: flex-start;
  align-items: center;

}

.colls {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 30px;
  flex-wrap: wrap;
}

.CLname {
  color: var(--Main-Text, #242424);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  /* 62.5% */
  max-width: 200px;
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: flex-start;
    align-items: center;
}

.CLimage {
  max-width: 94px;
  width: 100%;
  display: flex;
}

.users {
  display: flex;
  color: var(--Yellow-Text, #B88D00);
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 10px;
  /* 41.667% */
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.CLcat {
  color: #242424;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: column;
  gap: 5px;
max-width: 125px;
width: 100%;
}

.short {
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  padding: 11px 9px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.num {
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  display: inline-flex;
  padding: 11px 9px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.long {
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  display: inline-flex;
  padding: 11px 9px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.numbers {
  display: flex;
  gap: 5px;
  justify-content: center;
}

.CLactions {
  display: inline-flex;
  align-items: flex-start;
  gap: 10px;
}

.action {
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  width: 36px;
  height: 34px;
  padding: 5px 6px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.method {
  display: flex;
  flex-direction: column;
  gap: 5px;

}

.get {
  border-radius: 4px;
  background: #5AB581;
  display: flex;
  height: 32px;
  padding: 0px 27px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #FFF;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

.select-check {
  left: -1px;
  top: 20px;
  position: absolute;

}

.select-check input[type="checkbox"] {
  display: none;
}

.select-check label {
  /* Style the custom checkbox */
  position: relative;
  cursor: pointer;
}

.select-check label:before {
  /* Checkbox icon */
  content: "";
  position: absolute;
  left: 0;
  top: -12px;
  width: 37px;
  height: 37px;
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
opacity: 1;
}

.select-check input[type="checkbox"]:checked+label:before {
  /* Checked state */
  content: '🗸';
  border-color: rgba(184, 141, 0, 1);
  color: white;
  opacity: 1;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.75) 100%);

}

.cbutton1 {
  display: none;
}
.post-result{
  position: absolute;
  right: 16px;
    top: 69px;
    max-width: 324px;
    width: 100%;
    height: 324px;
}
.overlay1{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
}
.quote-popup{
  border-radius: 8px;
  background: #F9F9F9;
  max-width: 744px;
  width: 100%;
  height: 284px;
  flex-shrink: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.hrs{
  color: #000;
text-align: center;
font-family: Raleway;
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: normal;
width: 284px;
}

.ca{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.greenaction{
  display: flex;
  gap: 5px;
  flex-wrap: wrap;

}
.saction{
  display: flex;
  gap: 12px;
  flex-wrap: wrap;


}
.scat{
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

}
.brand-profile{
  width: 100%;
  height: 100%;
  object-fit: cover;

}
.profilewrapper{
  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.profileMwrapper{
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.searchwrapper{
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
}

@media (max-width:1162px) {
  .cbutton {
    color: var(--Background, #FEFEFE);
    text-align: center;
    font-family: Cabin;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    background: #404040;
    display: inline-flex;
    height: 37px;
    padding: 3px 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    flex-direction: row;

  }

  .collection {
    gap: 40px;
  }

  .collection h3 {
    color: var(--Container, #0C2D1A);
    font-family: Archivo Black;
    font-size: 33.36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }

  .collection p {
    color: #000;
    font-family: Raleway;
    font-size: 13.9px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    max-width: 400px;
  }

  .CLname {
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 10.917px;
    font-style: normal;
    font-weight: 700;
    line-height: 6.823px;
    /* 62.5% */
  }

  .users {
    color: var(--Yellow-Text, #B88D00);
    text-align: center;
    font-family: Inter;
    font-size: 16.375px;
    font-style: normal;
    font-weight: 700;
    line-height: 6.823px;
    /* 41.667% */
  }

  .users img {
    width: 16.375px;
    height: 16.375px;
  }

  .short {
    display: flex;
    width: 57.995px;
    padding: 7.505px 6.141px;
    justify-content: center;
    align-items: center;
    gap: 5.458px;
    color: #242424;
    font-family: Inter;
    font-size: 10.917px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .num {
    display: inline-flex;
    padding: 7.505px 6.141px;
    justify-content: center;
    align-items: center;
    gap: 5.458px;
    color: #242424;
    font-family: Inter;
    font-size: 10.917px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .long {
    display: inline-flex;
    padding: 7.505px 6.141px;
    justify-content: center;
    align-items: center;
    gap: 5.458px;
    color: #242424;
    font-family: Inter;
    font-size: 10.917px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .action img {
    width: 16.375px;
    height: 16.375px;
    flex-shrink: 0;
  }

  .action {
    display: flex;
    width: 24.563px;
    height: 23.198px;
    padding: 3.411px 4.094px;
    justify-content: center;
    align-items: center;
  }

  .get {
    color: #FFF;
    font-family: Raleway;
    font-size: 10.917px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .Cname {
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 10.917px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .Ccreators,
  .Ccategory,
  .Caction {
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 10.917px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}


@media (max-width:730px) {
  .coll {
    justify-content: center;
  }

  .colls {
    width: 90%;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .ctitle {
    display: none;
  }
}

@media (max-width:650px) {
  .cbutton1 {
    color: var(--Background, #FEFEFE);
    text-align: center;
    font-family: Cabin;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    background: #404040;
    display: inline-flex;
    height: 37px;
    padding: 3px 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    flex-direction: row;
  }

  .cbutton {
    /* display: none; */
  }

  .collection {
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin-top: 0;
  }

  .ctitle {
    display: none;
  }

  .not {
    min-height: 245px;
    flex-shrink: 0;
  }

  .collection h3 {
    justify-content: center;
    color: var(--Container, #0C2D1A);
    text-align: center;
    font-family: Archivo Black;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }

  .collection p {
    width: 90%;
    color: #000;
    text-align: center;
    font-family: Raleway;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .c-head {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

}
@media (max-width:900px) {
  .create-collection-popup{
    padding: 0;
    transform: none;
    left: 0;
    right: 0;
    bottom: 0;
    }
  .write input, .write textarea {
    width: 85%;
  }
  .vibe{
    display: none;
  }
}

@media (max-width:600px) {
  .h3 h3{
    color: var(--Main-Text, #242424);
font-family: Raleway;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
  }
  .write h3{
    color: var(--Main-Text, #242424);
font-family: Raleway;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
  }
}
@media (max-width:350px) {
  .insta-post{
    width: 265px;
  }
  .post-result{
    position: absolute;
    right: 12px;
    top: 52px;
    max-width: 240px;
    width: 100%;
    height: 238px;
  }
}