.welcome {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 60px;

}

.head {}

.head h3 {
    color: #0C2D1A;
    font-family: Archivo Black;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
}

.head p {
    max-width: 595px;
    color: #000;
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
}

.request {
    width: 250px;
    height: 48px;
    border-radius: 8px;
    background: #0C2D1A;
    color: #FEFEFE;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    z-index: 1;
    padding: 0px 12px;
    position: relative;
}

.arrow {
    position: absolute;
    left: 204px;
    top: 6px;
    transform: rotate(90deg);
    opacity: 0;
}

.request:hover {
    border-radius: 8px;
    background-color: #242424;
    cursor: pointer;

    .arrow {

        animation: arrowrotate 0.5s forwards;
    }
}

.filter-list {
    border-bottom: 1px solid #242424;
    background: #F6F6F6;
    max-width: 1152px;
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.list-head {
    display: flex;
    gap: 5px;
    flex-direction: column;
    text-align: left;
    margin-left: 73px;
}

.list-head h3 {
    color: #0C2D1A;
    font-family: Raleway;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
    margin-top: 0;
}

.list-head p {
    color: #242424;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
    margin-top: 0;
}

.filters {
    display: flex;
    gap: 12px;
    margin-right: 50px;
}

.filter {
    color: #242424;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    border: 0.5px solid #242424;
    background-color: #FEFEFE;
    width: 136px;
    padding-left: 10px;
    height: 32px;
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.sort {
    cursor: pointer;
}

.thead {
    border-bottom: 1px solid #959595;
    background-color: #EEE;
    max-width: 1152px;
    width: 100%;
    height: 47px;
    flex-shrink: 0;
    display: flex;
}

.th1 {
    color: #242424;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.th {
    color: #242424;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.creator {
    border-bottom: 1px solid #242424;
    background: #FDFDFD;
    max-width: 1152px;
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
    position: relative;
}

.creator-wrap {
    display: flex;
    max-width: 900px;
    width: 100%;
    height: 72px;
    align-items: center;
    gap: 28px;
    margin-left: 52px;
}

.thead-wrap {
    display: flex;
    max-width: 879px;
    width: 75%;
    align-items: center;
    gap: 32px;
    margin-left: 52px;

}

.name {
    display: flex;
    max-width: 236px;
    width: 100%;
    align-items: center;
    gap: 12px;
    height: 64px;
}

.name h3 {
    margin-top: 0px;
    margin-bottom: 0px;
    width: 160px;
    color: #242424;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 100% */
    text-align: left;
}

.follower {
    color: #B88D00;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    max-width: 110px;
    width: 100%;
    display: flex;
}

.city,
.content-type,
.creator-type {
    color: #242424;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 125% */
    text-align: left;

}

.content-type {
    max-width: 146px;
    width: 100%;
}

.creator-type {
    max-width: 146px;
    width: 100%;

}

.city {
    max-width: 146px;
    width: 100%;
}

.tname {
    max-width: 236px;
    width: 100%;
}

.tcreator-type {
    max-width: 146px;
    width: 100%;
}

.tcreator-type {
    max-width: 146px;
    width: 100%;

}

.tcity {
    max-width: 146px;
    width: 100%;

}

.blur {
    background: rgba(131, 131, 131, 0.10);
    backdrop-filter: blur(4px);
    position: absolute;
    width: 100%;
    height: 22%;
    bottom: 0;
    color: #525252;
    text-align: center;
    font-family: Raleway;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    right: 0;
    left: 0;
}

.blur h3 {
    max-width: 396px;
    margin-top: 0;
    margin-bottom: 0;
}

.social {
    max-width: 160px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    cursor: pointer;
}

.dots {
    cursor: pointer;
}
.filter-logo{
    display: none;
}

@keyframes arrowrotate {
    from {
        transform: rotate(90deg);
        opacity: 0;
    }

    to {
        transform: rotate(0deg);
        opacity: 1;
    }
}

@media (max-width:900px) {
    .request {
        border-radius: 8px;
        background-color: #242424;
        cursor: pointer;
    }

    .arrow {
        opacity: 1;
        transform: rotate(0deg);
    }
}

@media (max-width:1170px) {
    .welcome {
        width: 90%;
    }

    .right {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .creator-wrap {
        /* display: flex;
max-width: 610.905px;
width: 100%;
height: 50.04px;
padding: 11.12px 16.68px;
align-items: center;
gap: 33.36px; */
    }

    .thead-wrap {
        /* display: flex;
    max-width: 610.905px;
    width: 100%;
    height: 50.04px;
    align-items: center;
    gap: 33.36px; */
    margin-left: 24px;
    }

    .name h3 {
        max-width: 160px;
        width: 100%;
        color: var(--Main-Text, #242424);
        font-family: Inter;
        font-size: 13.9px;
        font-style: normal;
        font-weight: 500;
        line-height: 13.9px;
    }



    .follower {
        color: var(--Yellow-Text, #B88D00);
        font-family: Inter;
        font-size: 16.68px;
        font-style: normal;
        font-weight: 400;
        line-height: 13.9px;
        /* 83.333% */
    }

    .creator-type {}

}

@media (max-width:1012px) {
    .list-head h3 {
        color: var(--Container, #0C2D1A);
        font-family: Raleway;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .list-head p {
        color: var(--Main-Text, #242424);
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .filter {
        width: 101.47px;
        height: 22.24px;
        flex-shrink: 0;
        color: var(--Main-Text, #242424);
        font-family: Raleway;
        font-size: 11.12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .sort img {
        width: 22.24px;
        height: 22.24px;
    }

    .sort {
        width: 22.24px;
        height: 22.24px;
    }

    .social {
        max-width: 111px;
    }

    .head h3 {
        color: var(--Container, #0C2D1A);
        font-family: Archivo Black;
        font-size: 33.36px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }

    .head p {
        color: #000;
        font-family: Raleway;
        font-size: 13.9px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        max-width: 413.525px;
    }
    
}

@media (max-width:800px) {
    .search-bars{
        justify-content: center !important;
    }
    .welcome{
        flex-direction: column;
        align-items: center;
        margin-top: 24px;
        gap: 16px;
    }
    .head h3{
        justify-content: center;
        margin-bottom: 24px;
    }
    .creator-wrap{
        margin-left: 24px;
        gap: 24px;
    }
    .tname{
        color: var(--Main-Text, #242424);
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
    }
    .th{
        color: var(--Main-Text, #242424);
font-family: Inter;
font-size: 11.12px;
font-style: normal;
font-weight: 400;
line-height: normal;
    }
    .thead-wrap{
            max-width: 524px;

    }
    .list-head{
margin-left: 24px;
    }
    .filters{
        margin-right: 24px;
    }
    .city, .content-type, .creator-type {
        color: var(--Main-Text, #242424);
font-family: Inter;
font-size: 11.12px;
font-style: normal;
font-weight: 400;
line-height: 13.9px; /* 125% */
    }
    .thead-wrap{
        width: 70%;
    }
}
@media (max-width:650px) {
    .filters{
        display: none;
        
    }
    .filter-logo{
        display: flex;
        border-radius: 5.56px;
border: 1px solid var(--Main-Text, #242424);
background: var(--BG, #FEFEFE);
width: 61px;
height: 22.24px;
flex-shrink: 0;
justify-content: center;
align-items: center;
margin-right: 22px;
padding: 5px 12px;
    }
    .social{
        flex-direction: column;
        max-width: 70px;
    }
    .filter-logo1{
        max-width: 292px;
        width: 90%;
height: 32px;
flex-shrink: 0;
margin-right: 0;
margin-top: 18px;
margin-bottom: 29px;

    }
}