.headingss{
    width: 86%;
    display: flex;
    flex-direction: column;
    margin-top: 21px;
    margin-bottom: 60px;
}
.headingss h3{
    color: var(--Container, #0C2D1A);
    font-family: Archivo Black;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    display: flex;
}
.headingss p{
    color: #000;
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    max-width: 683px;
    text-align: left;
}
.user-detail{
    border-radius: 8px;
border: 1px solid var(--Main-Text, #242424);
background: #F9F9F9;
max-width: 996px;
width: 100%;
min-height: 160px;
flex-shrink: 0;
}
.profile-detail{
display: flex;
justify-content: space-around;
width: 93%;
}

.updateB{
    border-radius: 8px;
background: #5AB581;
display: flex;
width: 158px;
height: 32px;
justify-content: center;
align-items: center;
gap: 8px;
flex-shrink: 0;
color: #FFF;
font-family: Raleway;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
cursor: pointer;

}
.profile{
    background-color: rgba(0, 0, 0, 0.60);
    width: 100px;
height: 100px;
flex-shrink: 0;
border-radius: 50%;
gap: 8px;
color: #FFF;
font-family: Raleway;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.profile-feild{
display: flex;
width: 90%;
gap: 32px;
}
.update-btn{

}
.feilds{
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    column-gap: 30px;
    row-gap: 8px;
}
.feild{
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;

}
.feild input{
    border-radius: 8px;
    background: #EEE;
    width: 220px;
    /* width: 100%; */
height: 32px;
flex-shrink: 0;
border: none;
position: relative;
padding-left: 24px;
}
.feilds img{
    position: absolute;
    right: 5px;
    cursor: pointer;
}
.passwords{
    border-radius: 8px;
border: 1px solid var(--Main-Text, #242424);
background: #F9F9F9;
max-width: 996px;
width: 100%;
min-height: 218px;
flex-shrink: 0;
margin-top: 15px;
display: flex;
justify-content: center;
align-items: center;
}
.feilds1{
    row-gap: 16px;

}
.pass-head h3{
    color: var(--Main-Text, #242424);
font-family: Raleway;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.profile-feild1{
    flex-direction: column;
}
.pass-head{
    display: flex;
}
.notiications{
    border-radius: 8px;
border: 1px solid var(--Main-Text, #242424);
background: #F9F9F9;
max-width: 996px;
width: 100%;
height: 218px;
flex-shrink: 0;
margin-top: 21px;
margin-bottom: 20px;

}
.feilds2{
    width: 91%;
    gap: 16px;
    column-gap: 70px;

}
.toggler{
    display: flex;
    justify-content: space-between;
    width: 227px;
    color: #242424;
font-family: Raleway;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.slider1 {
    width: 32px;
    height: 16px;
    background-color: rgba(36, 36, 36, 1);
    border-radius: 15px;
    display: flex;
    cursor: pointer;
    align-items: center;

  }
  
  .slider1.active {
    background-color: #4caf50 !important; 
  }
  
  .slider-button {
    width: 13px;
    height: 13px;
    background-color: white;
    border-radius: 50%;
    transition: 0.2s;
  }
  
  
  .slider-button.active {
   /* background-color:  #FFC296; */
   background-color: white !important;
    transform: translateX(16px); /* Move the button to the right when active */
  }
  .selected{
    background-color: var(--Dark-blue);
    /* color: var(--primary-black); */
  }
  .active2{
    color: #1C541C;
font-family: Raleway;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
  }

  .profile-pic{
    width: 100px;
    height: 100px;
    object-fit: cover;
    flex-shrink: 0;
    border-radius: 50%;
  }
  .headings-logout{
    display: flex;
    align-items: end;
    width: 86%;
    justify-content: space-between;
  }
  .logout{
    border-radius: 8px;
background: #0C2D1A;
display: flex;
width: 124px;
height: 32px;
justify-content: center;
align-items: center;
gap: 8px;
flex-shrink: 0;
color: #FFF;
font-family: Raleway;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-bottom: 30px;
cursor: pointer;
  }
  .logoutimg{
    height: 18px;
    width: 18px;
  }
  .logout1{
    border-radius: 8px;
background: #0C2D1A;
display: flex;
width: 158px;
height: 32px;
justify-content: center;
align-items: center;
gap: 8px;
flex-shrink: 0;
color: #FFF;
font-family: Raleway;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-bottom: 30px;
cursor: pointer;
display: none;
  }
  .tick{
    height: 23px;
    width: 23px;
  }

@media (max-width:950px) {
    .update-btn{

    }
    .profile-detail{
        align-items: end;

    }
}
@media (max-width:650px) {
.profile-detail{
    flex-direction: column;
    gap: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    align-items: center;

}
.profile-feild{
    flex-direction: column;
    align-items: center;
}
.feilds{
    justify-content: center;
}
.logout{
    display: none;
}
.logout1{
    display: flex;
    margin-top: 24px;
}
}