.App {
  text-align: center;
  display: flex;
  position: relative;
}
.general-right{
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  flex-direction: column;

}
.right{
  max-width: 1152px;
  width: 98%;
  min-height: 100vh;
}
h3{
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
}
p{
  margin-top: 0;
  margin-bottom: 0;
}
.flex{
  display: flex;
  justify-content: center;
  align-items: center;
}
.cflex{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}