.navbar {
    width: 240px;
    height: 100vh;
    flex-shrink: 0;
    background: #242424;
    display: flex;
    flex-direction: column;

    position: sticky;
    top: 0;
    overflow-y: auto;

}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #B88D00;
    font-family: Playfair Display;
    font-size: 19.167px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    gap: 10px;
    height: 94px;
    width: 100%;

}

.nav-items {
    min-height: 297px;
    border-bottom: 1px solid #959595;
    border-top: 1px solid #959595;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.items {
    width: 192px;
    height: 48px;
    color: #959595;
    font-family: Archivo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding-left: 8px;
    cursor: pointer;
    position: relative;
}

.clicked {
    width: 192px;
    height: 48px;
    border-radius: 8px;
    background: #333;
    color: #FEFEFE;
    font-family: Archivo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding-left: 8px;
    cursor: pointer;
    position: relative;
}

.lock {
    position: absolute;
    left: -15px;
}


.item-img {
    position: absolute;
    padding-left: 8px;
}

.itemss:hover {
    color: #FEFEFE;
    font-family: Archivo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    .item-img {
        width: 11px;
        height: 11px;
        flex-shrink: 0;
        border-radius: 8px;
        background: #FFF;
        filter: blur(12px);
    }

}

.unlock-cover {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.unlock {
    border-radius: 10px;
    border: 1px solid #FEFEFE;
    background-color: #333;
    width: 192px;
    height: 101px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 7px;
}

.up {
    display: flex;
    gap: 9.3px;
    justify-content: center;
    align-items: center;
}

.up img {}

.easy {
    display: flex;
    flex-direction: column;
    width: 123px;

}

.more {
    color: #FEFEFE;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
}

.login {
    color: #959595;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
}

.sign {
    border-radius: 8px;
    background: #FEFEFE;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    width: 169px;
    padding: 8px 0px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #242424;
    text-align: center;
    font-family: Raleway;
    font-size: 13px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    cursor: pointer;
}

.privacy,
.terms {
    width: 192px;
    height: 48px;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: Archivo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-left: 8px;
    cursor: pointer;
    position: absolute;

}
.privacy:hover,
.terms:hover{
    border-radius: 8px;
background: #333;
}

.terms {
    bottom: 25px;
}

.privacy {
    bottom: 64px;

}

.quote-popups {
    border-radius: 8px;
    background: #F9F9F9;
    max-width: 744px;
    width: 100%;
    height: 384px;
    flex-shrink: 0;
    position: fixed;
    top: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: scroll;
    flex-direction: column;
    gap: 8px;

}

.pheading {
    color: #000;
    text-align: center;
    font-family: Raleway;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    max-width: 449px;
    margin-top: 8px;
}

.termss {}

.termss>li {
    list-style-type: none;

}

.termss>li>p {
    display: flex;
    text-align: left;

}

.quote-popups::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.quote-popups {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}