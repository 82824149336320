.signin{
    width: 50%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.drawing{
    background: #F9F9F9;
    height: 100%;
    width: 50%;
}
.draw-head{
    display: inline-flex;
flex-direction: column;
align-items: flex-start;
width: 90%;
margin-top: 32px;
}
.draw-head h3{
    color: var(--Container, #0C2D1A);
font-family: Archivo Black;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
}
.draw-head p{
    color: var(--Main-Text, #242424);
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    max-width: 424px;
    text-align: left;
}
.signin-head{
    display: inline-flex;
flex-direction: column;
align-items: flex-start;
width: 80%;
}
.signin-head h3{
    color: var(--Container, #0C2D1A);
font-family: Archivo Black;
font-size: 48px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
}
.signin-head p{
    color: #000;
font-family: Raleway;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.signbox{
    border-radius: 16px;
border: 1px solid var(--Main-Text, #242424);
background: #F9F9F9;
max-width: 458px;
width: 100%;
height: 365px;
flex-shrink: 0;
margin-top: 39px;
}
.user-pass{
    gap: 7px;
    width: 90%;
}
.user-pass input{
    max-width: 303px;
    width: 100%;
height: 40px;
flex-shrink: 0;
color: #959595;
font-family: Raleway;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
border: none;
border-radius: 8px;
background: #E8E8E8;
padding-left: 23px;
}
.forget{
    color: #959595;
font-family: Inter;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: normal;
width: 70%;
display: flex;
margin-top: 17px;
margin-bottom: 30px;
}
.gsign{
    gap: 7px;
    width: 100%;
}
.google{
    display: flex !important;
    gap: 12px !important;
    border-radius: 8px !important;
background-color: #5AB581 !important;
display: flex;
max-width: 328px;
width: 90%;
height: 40px;
padding: 0px 0px;
justify-content: center;
align-items: center;
gap: 8px;
flex-shrink: 0;
color: #FFF !important;
font-family: Raleway !important;
font-size: 16px !important;
font-style: normal !important;
font-weight: 700 !important;
line-height: normal;
cursor: pointer;
}
.google div{
    background: transparent !important;
    margin-right: 0px !important;
    padding: 0px !important;
    border-radius: 0px !important; 
}
.already{
margin-top: 36px;
cursor: pointer;
}
.already h3{
    color: #000;
text-align: center;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.signupbox{
    height: auto;
    min-height: 355px;
    padding-bottom: 32px;
    padding-top: 32px;

}
.s2 h3{
    text-align: left;
}
.forget p{
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 311px; 
    margin-bottom: 17px;
}
.forget2 h3{
    color: #000;
text-align: center;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.forget2 p{
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 311px;
    margin-bottom: 40px;
}
.number {
    background: #F9F9F9 !important;

}
.feild2{
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    width: 80%;
}
.feild2 img{
    position: absolute;
    right: 8px;
    cursor: pointer;
}
.resend{
    color: #000;
text-align: center;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-top: 41px;
}
.forget2 p{
    color: #000;
text-align: center;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.error-message{
    display: flex;
    color: red;
    max-width: 312px;
    width: 100%;
    flex-shrink: 0;
    color: #ca0000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-top: 4px;
    margin-bottom: 4px;
    font-family: Inter;

}
.errorMessage{
    flex-shrink: 0;
color: #ca0000;
text-align: center;
font-size: 16px;
font-style: normal;
font-weight: 400;
margin-top: 4px;
margin-bottom: 4px;
font-family: Inter;

}
.errored input{
    border: 2px solid red; ;
}

@media (max-width: 1170px){
    .drawing{
        width: 100%;
    }
    .signin{
        width: 100%;
    }
    .already{
        margin-bottom: 40px;
    }
    .signin-head{
        margin-top: 30px;
      }
}

@media (max-width: 900px){
  .signin-head{
    align-items: center;
  }
  /* .draw-head{
    align-items: center;
  } */
}

@media (max-width: 600px){

  .draw-head{
    align-items: center;
  }
  .drawing img{
    width: 374px;
height: 374px;
  }
  .draw-head p{
    text-align: center;
  }
  .signbox{
    width: 95%;
  }
  .user-pass input{
    width: 95%;
  }
}

@media (max-width: 380px){


  .drawing img{
    width: 278px;
height: 278px;
  }

}