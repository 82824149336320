.campaign-head {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    margin-bottom: 64px;
    width: 86%;
}

.campaign-head h3 {
    color: var(--Container, #0C2D1A);
    font-family: Archivo Black;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    max-width: 390px;
    text-align: left;
}

.campaign-head p {
    color: #000;
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    max-width: 683px;
    text-align: left;
}

.ctypes {
    display: flex;
    flex-direction: column;
    gap: 21px;
    flex-wrap: wrap;
    align-items: flex-end;
        max-width: 812px;
        width: 100%;
}
.backBtn{
    display: flex;
    gap: 8px;
}
.back{
    color: #407657;
font-family: Raleway;
font-size: 12.627px;
font-style: normal;
font-weight: 700;
line-height: normal;
border-radius: 6.313px;
border: 0.789px solid #407657;
display: flex;
width: 133px;
    height: 48px;
justify-content: center;
align-items: center;
gap: 6.313px;
flex-shrink: 0;
cursor: pointer;
}
.backs{
    color: #407657;
font-family: Raleway;
font-size: 12.627px;
font-style: normal;
font-weight: 700;
line-height: normal;
border-radius: 6.313px;
border: 0.789px solid #407657;
display: flex;
width: 133px;
    height: 48px;
justify-content: center;
align-items: center;
gap: 6.313px;
flex-shrink: 0;
cursor: pointer;
display: none;
}

.next {
    border-radius: 8px;
    background: #5AB581;
    display: flex;
    width: 133px;
    height: 48px;
    /* padding: 17px 50px; */
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    color: #FFF;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
}

.nexts {
    border-radius: 8px;
    background: #5AB581;
    display: flex;
    width: 133px;
    height: 48px;
    /* padding: 17px 50px; */
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    color: #FFF;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
}
.next1 {
    border-radius: 8px;
    background: #5AB581;
    display: flex;
    width: 133px;
    height: 48px;
    /* padding: 17px 50px; */
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    color: #FFF;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: none;
    cursor: pointer;
}


.ctabs {
    display: flex;
    gap: 24px;
    width: 100%;
}
.leftin{
align-items: baseline;
justify-content: flex-start;

}
.create-h{
    width: 100%;
}

.smart {
    border-radius: 8px;
    border: 1px solid var(--Main-Text, #242424);
    background: #FFFBF0;
    max-width: 366px;
    width: 100%;
    height: 174px;
    flex-shrink: 0;
    padding-left: 24px;
    display: flex;
    align-items: baseline;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    position: relative;
}

.shead {
    max-width: 177px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 29px;
    background: #FFF3CE;
    flex-shrink: 0;
}

.shead h3 {
    color: var(--Main-Text, #242424);
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.smart p {
    color: #000;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    max-width: 315px;
}

.chard {
    background: #F3F8FF;
    max-width: 188px;
    width: 100%;
    height: 29px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom {
    border-radius: 8px;
    border: 1px solid var(--Main-Text, #242424);
    background: #E5F0FF;
    max-width: 366px;
    width: 100%;
    height: 174px;
    flex-shrink: 0;
    padding-left: 24px;
    display: flex;
    align-items: baseline;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    position: relative;
}

.custom p {
    color: #000;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 315px;
    text-align: left;
}

.smart-campaign-steps {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.smart-heading {
    display: flex;
    flex-direction: column;
    margin-top: 90px;
    margin-bottom: 54px;
    width: 90%;

}

.smart-heading h3 {
    color: var(--Container, #0C2D1A);
    font-family: Archivo Black;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    max-width: 390px;
    text-align: left;

}

.smart-heading p {
    color: #000;
    leading-trim: both;
    text-edge: cap;
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    max-width: 683px;
    text-align: left;
}

.smart input,
.custom input {
    position: absolute;
    top: 15px;
    right: 14px;
   
}

.smarts.step1 {
    border-radius: 8px;
    border: 1px solid var(--Main-Text, #242424);
    background: #FFFEFD;
    max-width: 996px;
    width: 90%;
    min-height: 424px;
    flex-shrink: 0;
}

.smarts {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--Main-Text, #242424);
    background: #FFFEFD;
    max-width: 996px;
    width: 90%;
    min-height: 424px;
    flex-shrink: 0;
    margin-bottom: 40px;
    padding-bottom: 30px;
}

.description {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-top: 20px;
}

.desc {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.general {
    background: #FFF3CE;
    max-width: 213px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 29px;
    flex-shrink: 0;
}

.general h3 {
    color: var(--Main-Text, #242424);
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.desc p {
    color: #000;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 325px;
    text-align: left;
}

.working {
    border-radius: 8px;
    background: #FFFBEF;
    max-width: 926px;
    width: 90%;
    min-height: 288px;
    flex-shrink: 0;
    margin-top: 25px;
    flex-wrap: wrap;
    column-gap: 17px;
    padding-bottom: 28px;
    padding-top: 28px;
    gap: 13px;
}

.goals {
    border-radius: 8px;
    border: 1px solid #000;
    background: var(--White, #FEFEFE);
    max-width: 319px;
    width: 90%;
    height: 108px;
    flex-shrink: 0;
    position: relative;
    padding-left: 24px;
    align-items: baseline;
}

.goals h3 {
    color: #000;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.goals p {
    color: #000;
    font-family: Raleway;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 319px;
    display: flex;
    text-align: left;
}

.goals input {
    position: absolute;
    top: 12px;
    right: 14px;
}

.landing h3 {
    color: var(--Main-Text, #242424);
    font-family: Raleway;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 65px;
    margin-bottom: 68px;
}

.asking {
    gap: 24px;
    width: 90%;
    flex-wrap: wrap;

}

.ask {
    border-radius: 8px;
    border: 1px solid #000;
    background: var(--White, #FEFEFE);
    max-width: 319px;
    width: 90%;
    height: 111px;
    flex-shrink: 0;
    padding-left: 24px;
    position: relative;
    align-items: baseline;
    gap: 8px;
}

.ask h3 {
    color: #000;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: left;

}

.ask p {
    color: #000;
    font-family: Raleway;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 256px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: left;
}

.ask input[type=radio] {
    position: absolute;
    top: 12px;
    right: 14px;
}

.ask input[type=text] {
    border-radius: 8px;
    background: #EEE;
    max-width: 251px;
    width: 90%;
    height: 24px;
    flex-shrink: 0;
    border: none;
    color: #959595;
    font-family: Raleway;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 13px;
}

.age {
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    gap: 8px;
}

.age-head {
    border-radius: 8px;
    border: 1px solid #000;
    background: #FFF;
    width: 142px;
    height: 32px;
    flex-shrink: 0;
    color: #959595;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    justify-content: baseline;
    align-items: center;
    padding-left: 20px;
}

.gender {
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    gap: 8px;
}

.gender-head {
    border-radius: 8px;
    border: 1px solid #000;
    background: #FFF;
    width: 142px;
    height: 32px;
    flex-shrink: 0;
    color: #959595;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    justify-content: baseline;
    align-items: center;
    padding-left: 20px;
}

.location {
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    gap: 8px;
}

.location-head {
    border-radius: 8px;
    border: 1px solid #000;
    background: #FFF;
    max-width: 239px;
    width: 90%;
    height: 32px;
    flex-shrink: 0;
    color: #959595;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 20px;
    display: flex;
    justify-content: baseline;
    align-items: center;
}

.range {
    border-radius: 8px;
    border: 1px solid #000;
    background: #FFF;
    width: 162px;
    flex-shrink: 0;
    padding-left: 20px;
}

.genders {
    width: 162px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #000;
    background: #FFF;
}
.all-fix{
border-bottom: 0.5px solid var(--Black, #242424) !important;
position: sticky;
    top: 0;
    background: #FFF;

}
.locations {
    border-radius: 8px;
    border: 1px solid #000;
    background: #FFF;
    width: 259px;
    height: 161px;
    flex-shrink: 0;
    overflow-y: scroll;
    position: relative;
}

.link-insta {
    display: flex;
    width: 100%;
    justify-content: space-around;
    gap: 40px;
}

.link {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    width: 280px;
}

.link h3 {
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 216px;
    text-align: left;
}

.link input {
    color: #959595;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    border: 1px solid #000;
    background: #FFF;
    max-width: 261px;
    height: 32px;
    flex-shrink: 0;
    padding-left: 15px;
    width: 90%;
}

.owns {
    height: 100%;
    gap: 16px;
    flex: auto;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 32px;

}

.own {
    border-radius: 8px;
    border: 1px solid #000;
    background: #FFFBEF;
    max-width: 394px;
    width: 98%;
    height: 191px;
    flex-shrink: 0;
    gap: 12px;
    position: relative;
    flex-direction: column;
    padding-left: 30px;
    align-items: baseline;

}

.own input[type=radio] {
    position: absolute;
    top: 20px;
    right: 22px;
}

.own h3 {
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
}

.own p {
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 313px;
    text-align: left;
}

.c-type {
    border-radius: 8px;
    border: 1px solid #000;
    background: var(--White, #FEFEFE);
    max-width: 252px;
    width: 100%;
    height: 116px;
    flex-shrink: 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-direction: column;
    gap: 8px;
    padding-left: 23px;
}

.types {
    width: 100%;
    gap: 17px;
    height: 100%;
    flex: auto;
    flex-wrap: wrap;
    margin-top: 32px;

}

.c-type input {
    position: absolute;
    top: 14px;
    right: 20px;
}

.c-type input[type='radio']::before {
    width: 16px;
    height: 16px;
}

.c-type h3 {
    color: #000;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.c-type p {
    max-width: 176px;
    color: #000;
    font-family: Raleway;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.posts {
    margin-top: 40px;
    margin-bottom: 120px;
    gap: 70px;
    width: 90%;
    justify-content: space-around;
    flex-wrap: wrap;
}

.post {
    border-radius: 8px;
    border: 1px solid #000;
    background: var(--White, #FEFEFE);
    width: 285px;
    height: 429px;
    flex-shrink: 0;
    gap: 12px;
}

.post1 {
    border: 1px dashed #000;
    background: #F9F9F9;
    width: 255px;
    height: 454px;
    flex-shrink: 0;
    position: relative;
}

.pre {
    color: #000;
    text-align: center;
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 156px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 254px;
}
.pre1 {
    color: #000;
    text-align: center;
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 156px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 400px;
}

.post p {
    color: var(--Black, #242424);
    text-align: center;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    max-width: 109px;
}

.browse {
    color: #FFF;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 8px;
    background: #5AB581;
    display: flex;
    width: 119px;
    height: 32px;
    padding: 2px 3px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    cursor: pointer;
}

.preview {
    position: absolute;
    top: -40px;
    color: var(--Main-Text, #242424);
    text-align: center;
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.pre {
    color: #000;
    text-align: center;
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
}

.pos {
    max-width: 400px;
    width: 90%;
    height: 400px;
}

.budgets {
    gap: 25px;
    width: 90%;
    flex: auto;
    height: 100%;
    flex-wrap: wrap;
    margin-top: 32px;
}

.set {
    border-radius: 8px;
    border: 1px solid #000;
    background: #FFFBEF;
    max-width: 424px;
    width: 90%;
    height: 191px;
    flex-shrink: 0;
}

.result {
    border-radius: 8px;
    border: 1px solid #000;
    background: #FFFBEF;
    max-width: 279px;
    width: 90%;
    height: 191px;
    flex-shrink: 0;
    gap: 4px;
}

.set p {
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    width: 81%;
    display: flex;

}

.cost {
    color: var(--Yellow-Text, #B88D00);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.brange {
    margin-top: 24px;
    margin-bottom: 24px;
    gap: 8px;
}

.discount {
    width: 100%;
}

.discount-slider {
    max-width: 337px;
    width: 90%;
}

.estimate {
    width: 90%;
    display: flex;
    align-items: baseline;
    gap: 4px;
}

.estimate h3 {
    color: var(--Main-Text, #242424);
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    max-width: 127px;
    text-align: left;
}

.acc-reach {
    border-radius: 8px;
    background: #FFF3CE;
    max-width: 246px;
    width: 100%;
    height: 43px;
    flex-shrink: 0;
    width: 90%;
    justify-content: space-around;
}

.limith {
    color: var(--Main-Text, #242424);
    font-family: Raleway;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.limit-h {
    color: var(--Yellow-Text, #B88D00);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
}

.total {
    color: var(--Yellow-Text, #B88D00);
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.smarty p {
    max-width: 258px;
    color: #000;
    text-align: center;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.smarty {
    gap: 10px;
}

.custom-general {
    background: #E5F0FF;
}

.custom-working {
    border-radius: 8px;
    background: #E5F0FF;
}

.set h3 {
    color: var(--Main-Text, #242424);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 82%;
    display: flex;

}

.set-custom {
    border-radius: 8px;
    border: 1px solid #000;
    background: #EBF4FF;
}

.customs {
    border-radius: 8px;
    border: 1px solid var(--Main-Text, #242424);
    background: #F6FAFF;
}

.not h3 {
    color: #000;
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.importC {
    color: #407657;
    leading-trim: both;
    text-edge: cap;
    font-family: Raleway;
    font-size: 12.627px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 6.313px;
    border: 0.789px solid #407657;
    display: flex;
    width: 120.265px;
    height: 32.253px;
    justify-content: center;
    align-items: center;
    gap: 6.313px;
    flex-shrink: 0;
}

.both-btn {
    display: flex;
    gap: 8px;
    cursor: pointer;
}

.custom-next {
    border-radius: 6.313px;
    background: #5AB581;
    display: flex;
    width: 119px;
    height: 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    cursor: pointer;
}
.custom-next1 {
    border-radius: 6.313px;
    background: #5AB581;
    display: flex;
    width: 119px;
    height: 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    cursor: pointer;
    display: none;
    color: #FFF;

font-family: Raleway;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.custom-back{
    color: #407657;
font-family: Raleway;
font-size: 12.627px;
font-style: normal;
font-weight: 700;
line-height: normal;
border-radius: 6.313px;
border: 0.789px solid #407657;border-radius: 6.313px;
border: 0.789px solid #407657;display: flex;
width: 119px;
    height: 32px;
justify-content: center;
align-items: center;
gap: 6.313px;
flex-shrink: 0;

}
.custom-back1{
    color: #407657;
font-family: Raleway;
font-size: 12.627px;
font-style: normal;
font-weight: 700;
line-height: normal;
border-radius: 6.313px;
border: 0.789px solid #407657;border-radius: 6.313px;
border: 0.789px solid #407657;display: flex;
width: 119px;
    height: 32px;
justify-content: center;
align-items: center;
gap: 6.313px;
flex-shrink: 0;
 display: none;
 
}

.custom-collection {
    padding-top: 18px;
    padding-bottom: 18px;
    margin-bottom: 40px;
    height: 261px;
    align-items: baseline;
    min-height: 222px;
    overflow-y: scroll;
}
.custom-collection::-webkit-scrollbar {
    display: none;
  }
  .rang{
    height: 33px;
    border-bottom: 0.5px solid #959595;;
    padding-left: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    color: var(--Main-Text, #242424);
font-family: Inter;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  /* CSS for the custom checked checkbox */
  .custom-checkbox1 input[type="checkbox"] {
    display: none;
  }
  
  .custom-checkbox1 label {
    /* Style the custom checkbox */
    position: relative;
    cursor: pointer;
  }
  
  .custom-checkbox1 label:before {
    /* Checkbox icon */
    content: "";
    position: absolute;
    left: 0;
    top: -10px;
    width: 20px;
    /* Adjust size as needed */
    height: 20px;
    /* Adjust size as needed */
    border: 2px solid rgba(184, 141, 0, 1);
    /* Checkbox border color */
    background-color: #fff;
    /* Checkbox background color */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }
  
  .custom-checkbox1 input[type="checkbox"]:checked+label:before {
    /* Checked state */
    content: '🗸';
    border-color: rgba(184, 141, 0, 1);
    color: rgba(184, 141, 0, 1);
  }
  
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .custom-collection {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .all-cover{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .custom-campaign-steps{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .leadw{
    min-height: 384px;
    justify-content: flex-start;
  }
.ipost{
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    object-fit: cover;
    /* max-width: 254px; */
    /* overflow: hidden; */
}
.post-top{
    border-radius: 50.738px 50.738px 0px 0px;
background: #FFF;
width: 95%;
height: 65px;
display: flex;
align-items: center;
justify-content: flex-start;
padding-left: 20px;
border: 0.5px solid black;
}
.post-top img{
height: 60px;
width: 60px;
}
.user{
align-items: flex-start;
}
.user h3{
    color: #2D2936;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.user p{
    color: #2D2936;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
}
.bottom{
background: #FFF;
border: 0.5px solid black;

}
.header{
    position: absolute;
    top: 0;
}
.header img{
    max-width: 288px;
    height: 31px;
}
.foter{
    position: absolute;
    bottom:  0;
}
.foter img{
    max-width: 260px;
    height: 30px;

}
.reels-result{
position: absolute;
z-index: 1;
}
.reels-review{
    z-index: 2;
}
.video-slider{
    position: absolute;
    top: 0;
}
.left-vid{
    position: absolute;
    top: 242px;
    right: 10px;
}
.right-vid{
    position: absolute;
    top: 242px;
    right: -289px;
}
.slider {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 15px;
    top: 68px;
    max-width: 324px;
    width: 100%;
    height: 323px;
  }
  
  .image {
    max-width: 320px;
    height: 322px;
    object-fit: cover;

  }
  
  .left-arrow {
    position: absolute;
    top: 50%;
    left: -35px;
    transform: translateY(-50%);
    font-size: 3rem;
    color: #000;
    cursor: pointer;
    z-index: 10;
    user-select: none;
  }
  
  .right-arrow {
    position: absolute;
    top: 50%;
    right: -35px;
    transform: translateY(-50%);
    font-size: 3rem;
    color: #000;
    cursor: pointer;
    z-index: 10;
    user-select: none;
  }
  
  .slide {
    opacity: 0;
    transition: all ease 1s;
  }
  
  .slide.active {
    opacity: 1;
    display: flex;
  }
  .campign-blob{
    background-image: url('C:\Users\91880\OneDrive\Desktop\Webminix projects\Creators-Dashboard\dashboard\src\media\elipse.png');
    background-repeat: no-repeat;
    background-position: top right;
    width: 100%;
  }
  .cbl{
    color: #0e0b0b !important;
  }
  
  @media (max-width:1300px) {
    .create-h{
        width: 82%;
    }
    .leftin{
        align-items: center;
    }
    .ctypes{
        flex-direction: column-reverse;
        flex-wrap: wrap;
    }
  }
  @media (max-width:1170px) {
    .link-insta{
        flex-direction: column;
        align-items: center;
    }

  }


  @media (max-width:900px) {
.ctabs{
    flex-wrap: wrap;
    justify-content: center;
}
.ctypes{
    align-items: center;
}
.posts{
    margin-bottom: 30px;
}
  }
  @media (max-width:600px) {
    .next{
        display: none;
    }
    .next1{
        display: flex;
    }
    .back{
        display: none;

    }
    .backs{
        display: flex;
    }
    .custom-next{
        display: none;
    }
    .custom-next1{
        display: flex;
    }
    .custom-back{
        display: none;
    }
    .custom-back1{
        display: flex;
    }
    .smarts{
        border: none;
    }
    .smarts.step1{
        border: none;
    }
    .campaign-head{
        align-items: center;
        width: 95%;

    }
    .campaign-head h3{
        align-items: center;
        text-align: center;
      
    }
    .campaign-head p{
        text-align: center;
     
    }
    .description{
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        }
    .desc{
        align-items: center;
    }
    .desc p{
        text-align: center;
    }
    .smart-heading h3{
        text-align: center;
    }
    .smart-heading{
        align-items: center;
    }
    .smart-heading p{
        text-align: center;
    }
    .backBtn{
        flex-direction: column;
    }
  }
  
  @media (max-width:450px) {

    .campaign-head h3{
        align-items: center;
        text-align: center;
        color: var(--Container, #0C2D1A);
text-align: center;
font-family: Archivo Black;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
max-width: 220px;
    }
    .campaign-head p{
        text-align: center;
        color: #000;
text-align: center;
font-family: Raleway;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: normal;
    }
    .smart{
        width: 95%;

    }
    .custom{
        width: 95%;
    }
    .smart-heading h3{
        color: var(--Container, #0C2D1A);
text-align: center;
font-family: Archivo Black;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
max-width: 174px;
    }
    .smart-heading p{
        color: #000;
        text-align: center;
        font-family: Raleway;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        max-width: 311px;
    }
    .location{
        width: 80%;
    }
    .locations{
        max-width: 239px;
        width: 90%;
    }
    .link{
        max-width: 280px;
        width: 90%;
    }
  }
  @media (max-width:320px) {
.reels-result{
    max-width: 267px;

}
  }